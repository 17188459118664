<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="creditTransactions"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="creditTransactionsPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Credit Transactions</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('credit_transaction_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Credit Transaction
                  </v-btn>
                </template>
                <v-card :class="customClass">
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Balance -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCreditTransaction.balance"
                              :rules="balanceRules"
                              label="Balance"
                              required
                              type="number"
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Balance <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Credit -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCreditTransaction.credit"
                              :rules="creditRules"
                              label="Credit"
                              type="number"
                            >
                              <template v-slot:label>
                                <p class="body-1">Credit</p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Credits -->
                          <v-col class="mt-n5" cols="12">
                            <v-select
                              v-model="editedCreditTransaction.credit_id"
                              :items="creditIdsList"
                              :rules="creditIdRules"
                              attach
                              label="Credits"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Credit Id <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- Debit -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCreditTransaction.debit"
                              :rules="debitRules"
                              label="Debit"
                              type="number"
                            >
                              <template v-slot:label>
                                <p class="body-1">Debit</p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Description -->
                          <v-col class="mt-n5" cols="12">
                            <v-textarea
                              v-model="editedCreditTransaction.description"
                              :rules="descriptionRules"
                              label="Description"
                            >
                              <template v-slot:label>
                                <p class="body-1">Description</p>
                              </template>
                            </v-textarea>
                          </v-col>

                          <!-- Number Of Credits -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="
                                editedCreditTransaction.number_of_credits
                              "
                              :rules="numberOfCreditsRules"
                              label="Number Of Credits"
                              required
                              type="number"
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Number Of Credits
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- OZOW Link -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCreditTransaction.ozow_link"
                              :rules="ozowLinkRules"
                              label="OZOW Link"
                            >
                              <template v-slot:label>
                                <p class="body-1">OZOW Link</p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- OZOW Payment Request Id -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="
                                editedCreditTransaction.ozow_payment_request_id
                              "
                              :rules="ozowPaymentRequestIdRules"
                              label="OZOW Payment Request Id"
                            >
                              <template v-slot:label>
                                <p class="body-1">OZOW Payment Request Id</p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Reference -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCreditTransaction.reference"
                              :rules="referenceRules"
                              label="Reference"
                            >
                              <template v-slot:label>
                                <p class="body-1">Reference</p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Services -->
                          <v-col class="mt-n5" cols="12">
                            <v-select
                              v-model="serviceName"
                              :items="serviceNamesList"
                              :rules="serviceNameRules"
                              attach
                              label="Services"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Service Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- Status -->
                          <v-col class="mt-n5" cols="12">
                            <v-select
                              v-model="editedCreditTransaction.status"
                              :items="statusesList"
                              :rules="statusRules"
                              attach
                              label="Status"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Status <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure you want to delete this credit transaction?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary"
                      @click="deleteCreditTransactionConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('credit_transaction_update')"
              small
              class="mr-2"
              @click="editCreditTransaction(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('credit_transaction_delete')"
              small
              @click="deleteCreditTransaction(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Credit Transactions per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementCreditTransactions",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Credit", value: "credit" },
        { text: "Credit Id", value: "credit_id" },
        { text: "Debit", value: "debit" },
        { text: "Number Of Credits", value: "number_of_credits" },
        { text: "Balance", value: "balance" },
        { text: "status", value: "status" },
        { text: "Service Id", value: "service_id" },
        { text: "Description", value: "description" },
        { text: "OZOW Payment Request Id", value: "ozow_payment_request_id" },
        { text: "OZOW Link", value: "ozow_link" },
        { text: "Reference", value: "reference" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      creditTransactions: [],

      credits: [],
      creditIdsList: [],

      services: [],
      serviceName: "",
      serviceNamesList: [],

      statusesList: [
        "Abandoned",
        "Awaiting Payment",
        "Cancelled",
        "Complete",
        "Error",
        "Pending",
        "PendingInvestigation",
      ],

      editedIndex: -1,
      editedCreditTransaction: {
        id: 0,
        balance: "",
        credit: "",
        credit_id: "",
        debit: "",
        description: "",
        number_of_credits: "",
        ozow_link: "",
        ozow_payment_request_id: "",
        reference: "",
        service_id: "",
        status: "",
        created_at: "",
        updated_at: "",
      },
      defaultCreditTransaction: {
        id: 0,
        balance: "",
        credit: "",
        credit_id: "",
        debit: "",
        description: "",
        number_of_credits: "",
        ozow_link: "",
        ozow_payment_request_id: "",
        reference: "",
        service_id: "",
        status: "",
        created_at: "",
        updated_at: "",
      },

      // balance
      balanceRules: [(v) => !!v || "Required"],

      // credit
      creditRules: [],

      // creditId
      creditIdRules: [(v) => !!v || "Required"],

      // debit
      debitRules: [],

      // description
      descriptionRules: [],

      // numberOfCredits
      numberOfCreditsRules: [(v) => !!v || "Required"],

      // ozowLink
      ozowLinkRules: [],

      // ozowPaymentRequestId
      ozowPaymentRequestIdRules: [],

      // reference
      referenceRules: [],

      // serviceName
      serviceNameRules: [(v) => !!v || "Required"],

      // status
      statusRules: [(v) => !!v || "Required"],

      page: 1,
      creditTransactionsPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],

      customClass: "",
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1
        ? "New Credit Transaction"
        : "Edit Credit Transaction";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedCreditTransactions();
    },

    // creditTransactionsPerPage
    creditTransactionsPerPage() {
      this.getApiPagedCreditTransactions();
    },

    // perPageChoice
    perPageChoice(val) {
      this.creditTransactionsPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("credit_transaction_access")) {
      this.$router.push({ name: "DashboardInbox" });
    } else {
      // this.getApiCreditTransactions();
      this.getApiPagedCreditTransactions();

      // Super Admin
      if (this.$can("super_admin")) {
        this.getApiServices();
        this.getApiCredits();

// customClass
this.customClass = "padding-bottom";
      } else {
        this.headers = [
          { text: "Id", align: "start", value: "id" },
          { text: "Credit", value: "credit" },
          { text: "Credit Id", value: "credit_id" },
          { text: "Debit", value: "debit" },
          { text: "Number Of Credits", value: "number_of_credits" },
          { text: "Balance", value: "balance" },
          { text: "status", value: "status" },
          { text: "Service Id", value: "service_id" },
          { text: "Description", value: "description" },
          { text: "Reference", value: "reference" },
          { text: "Created At", value: "created_at" },
          { text: "Updated At", value: "updated_at" },
          { text: "Actions", value: "actions", sortable: false },
        ];
      }
    }
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiCreditTransaction();
        } else {
          this.createApiCreditTransaction();
        }
      }
    },

    // editCreditTransaction
    editCreditTransaction(creditTransaction) {
      this.editedIndex = this.creditTransactions.indexOf(creditTransaction);
      this.editedCreditTransaction = Object.assign({}, creditTransaction);
      this.dialog = true;

      if (this.editedIndex != -1) {
        this.serviceName = this.$helpers.getNameByIdFromList(
          this.services,
          creditTransaction.service_id
        );
      }
    },

    // deleteCreditTransaction
    deleteCreditTransaction(creditTransaction) {
      this.editedIndex = this.creditTransactions.indexOf(creditTransaction);
      this.editedCreditTransaction = Object.assign({}, creditTransaction);
      this.dialogDelete = true;
    },

    // deleteCreditTransactionConfirm
    deleteCreditTransactionConfirm() {
      this.deleteApiCreditTransaction();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedCreditTransaction = Object.assign(
          {},
          this.defaultCreditTransaction
        );
        this.editedIndex = -1;
        this.serviceName = "";
      });

      this.$refs.form.resetValidation();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedCreditTransaction = Object.assign(
          {},
          this.defaultCreditTransaction
        );
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // getApiCreditTransactions
    async getApiCreditTransactions() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("credit-transactions");

      if (res.status == 200) {
        this.creditTransactions = res.data;
      }

      this.overlay = false;
    },

    // getApiPagedCreditTransactions
    async getApiPagedCreditTransactions() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `credit-transactions/paged?page_no=${this.page}&page_size=${this.creditTransactionsPerPage}`
      );

      if (res.status == 200) {
        this.creditTransactions = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // createApiCreditTransaction
    async createApiCreditTransaction() {
      this.overlay = true;

      var reqData = {
        balance: this.editedCreditTransaction.balance,
        credit: this.editedCreditTransaction.credit,
        credit_id: this.editedCreditTransaction.credit_id,
        debit: this.editedCreditTransaction.debit,
        description: this.editedCreditTransaction.description,
        number_of_credits: this.editedCreditTransaction.number_of_credits,
        ozow_link: this.editedCreditTransaction.ozow_link,
        ozow_payment_request_id:
          this.editedCreditTransaction.ozow_payment_request_id,
        reference: this.editedCreditTransaction.reference,
        service_id: this.$helpers.getIdByNameFromList(
          this.services,
          this.serviceName
        ),
        status: this.editedCreditTransaction.status,
      };

      const res = await this.$helpers.createApiData(
        "credit-transactions",
        reqData,
        "Credit Transaction Created Successfully!"
      );

      if (res.status == 200) {
        this.creditTransactions.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // updateApiCreditTransaction
    async updateApiCreditTransaction() {
      this.overlay = true;

      var reqData = {
        id: this.editedCreditTransaction.id,
        balance: this.editedCreditTransaction.balance,
        credit: this.editedCreditTransaction.credit,
        credit_id: this.editedCreditTransaction.credit_id,
        debit: this.editedCreditTransaction.debit,
        description: this.editedCreditTransaction.description,
        number_of_credits: this.editedCreditTransaction.number_of_credits,
        ozow_link: this.editedCreditTransaction.ozow_link,
        ozow_payment_request_id:
          this.editedCreditTransaction.ozow_payment_request_id,
        reference: this.editedCreditTransaction.reference,
        service_id: this.$helpers.getIdByNameFromList(
          this.services,
          this.serviceName
        ),
        status: this.editedCreditTransaction.status,
      };

      const res = await this.$helpers.updateApiData(
        `credit-transactions/${this.editedCreditTransaction.id}`,
        reqData,
        "Credit Transaction Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.creditTransactions[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // deleteApiCreditTransaction
    async deleteApiCreditTransaction() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `credit-transactions/${this.editedCreditTransaction.id}`,
        "Credit Transaction Deleted Successfully!"
      );

      if (res.status == 200) {
        this.creditTransactions.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // Get Api Credits
    async getApiCredits() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("credits");

      if (res.status == 200) {
        this.credits = res.data;

        this.creditIdsList = this.credits.map(({ id }) => id);
      }

      this.overlay = false;
    },

    // Get Api Services
    async getApiServices() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("services");

      if (res.status == 200) {
        this.services = res.data;

        this.serviceNamesList = this.$helpers.getNamesFromList(this.services);
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.padding-bottom {
  padding-bottom: 180px;
}
</style>
